import { bank_transfer, bca, bni, bri, channel_shopee, credit_card, gopay, permata_bank, qris } from "assets/images";

export const iconsPayment = [
   {
     code: 'banktransfer.banktransfer',
     contains: ['banktransfer'],
     name: bank_transfer
   },
   {
     code: 'midtrans.credit_card',
     contains: ['credit'],
     name: credit_card
   },
   {
     code: 'midtrans.gopay',
     contains: ['gopay'],
     name: gopay
   },
   {
     code: 'midtrans.shopeepay',
     contains: ['shopeepay'],
     name: channel_shopee
   },
   {
     code: 'midtrans.permata_va',
     contains: ['permata'],
     name: permata_bank
   },
   {
     code: 'midtrans.bca_va',
     contains: ['bca'],
     name: bca
   },
   {
     code: 'midtrans.bri_va',
     contains: ['bri'],
     name: bri
   },
   {
     code: 'midtrans.bni_va',
     contains: ['bni'],
     name: bni
   },
   {
     code: 'xendit.qris.QR',
     contains: ['qris'],
     name: qris
   }
]
