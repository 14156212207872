import { useContext } from 'react'
import {
  ViewContext,
} from '.'
import ResizeDetector from 'react-resize-detector'
import useStyles from './styles'

export const SplitViewPane = ({ pane, width, additionalInset, ...props }) => {
  const classes = useStyles()

  const viewContext = useContext(ViewContext)
  const { fixedPosition = false } = props
  const { SplitViewPaneScroll } = props
  let fixedStyle = {}

  if (fixedPosition) {
    fixedStyle = {
      position: "absolute",
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    }

    if (viewContext.containerSize.height > 0)
      fixedStyle['height'] = viewContext.containerSize.height - (additionalInset || 0)
    else if (viewContext.containerInsets.top > 0)
      fixedStyle['height'] = `calc(100%-${viewContext.containerInsets.top}px)-${additionalInset || 0}px)`
    else
      fixedStyle['height'] = '100%'
  }

  if (fixedPosition === "fixed") {
    return (
      <ResizeDetector detectWidth>
        {({ width }) => (
          <div style={fixedStyle} {...props}>
            <div style={{ position: "fixed", width, ...SplitViewPaneScroll }}>
              {props.children}
            </div>
          </div>
        )}
      </ResizeDetector>
    )
  }

  return (
    <div style={fixedStyle} {...props}>
      {props.children}
    </div>
  )
}

export default SplitViewPane