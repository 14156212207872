import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#ececec',
  },
  linearProgress: {
    position: 'fixed',
    height: 2,
    overflow: 'hidden',
    width: '100%',
    zIndex: 1250 // Above Drawer (1200)
  },
  menuPane: {
    backgroundColor: '#efefef',
  },
  menuIcon: {
    width: '5% !important',
    minWidth: '75px !important',
  },
  contentPane: {
    backgroundColor: '#fff',
  },
  listViewCardListInset: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },  
  listViewTableInset: {
    // marginLeft: theme.spacing(-2),
    // marginRight: theme.spacing(-2),
  },
  listViewFilterBar: {
    marginBottom: theme.spacing(1),
  },
  listViewFilterBarCardInset: {
    // marginLeft: theme.spacing(-2),
    // marginRight: theme.spacing(-2),
  },
  listViewNavigationBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  listViewNavigationBarItemSpacing: {
    width: theme.spacing(1),
  },
  detailViewTabs: {
    marginTop: theme.spacing(0),
    // marginBottom: theme.spacing(2),
  },
  detailViewSidePanelTabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    // justifyContent: "center",
    // border: "solid 1px"
  },
  sidebarTabVMargin: {
    // marginTop: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(0.5)
  },
  listViewFilterButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    button: {
      backgroundColor: 'red'
    }
  },
  groupByBar: {
    // display: 'flex',
    // flexDirection: 'row',
    position: 'relative',
    width: '99%',
    display: '-webkit-inline-box',
    overflowX: 'auto',
    // alignItems: 'center',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRight: 'none',
    border: "1px solid #efefef",
    // gap: theme.spacing(1.5),
    '&::-webkit-scrollbar': {
      width: theme.spacing(0),
      height: theme.spacing(0)
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: theme.spacing(0),
      backgroundColor: '#f2f2f2'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(0),
      backgroundColor: '#ccc',
    },
    scrollBehavior: 'smooth',
  },
  groupByTitle: {
    marginRight: theme.spacing(.5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  groupChannelIcon: {
    height: 22,
    width: 22
  },
  badgeDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(.5),
    borderRadius: theme.spacing(2),
    backgroundColor: '#ff6f00',
    minWidth: 10,
    height: 10,
    marginLeft: theme.spacing(.7)
  },
  groupByBarReserveSpace: {
    width: theme.spacing(10)
  },
  groupByBarScrollBtn: {
    position: 'absolute',
    right: 10,
    top: 12
  },
}))

export default useStyles