import React, { useRef } from 'react'
import clsx from 'clsx'
import { IMaskInput } from 'react-imask'
import NumberFormat from 'react-number-format'
import {
  Text,
  ImageInput,
  ImageListInput,
  Button,
  FileListInput
} from '.'
import {
  useTheme,
  Box,
  TextField,
  Input,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  InputLabel,
  Checkbox,
  FormHelperText,
  MenuItem,
  InputAdornment,
  IconButton,
  Chip
} from "@material-ui/core"
import { DatePicker as MuiDatePicker, TimePicker as MuiTimePicker } from '@material-ui/pickers'
import DatePicker from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import { Clear as ClearIcon } from '@material-ui/icons'
import CheckIcon from '@material-ui/icons/Check';
import { MentionsInput, Mention } from 'react-mentions'
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete"

import moment from 'moment'
import { isEqual, countBy, pickBy, difference, isNull, mergeWith } from 'lodash'
import { useDispatch } from 'react-redux'
//import DayPicker, { DateUtils } from 'react-day-picker'
//import 'react-day-picker/lib/style.css'
import './calendar.css'
import PropTypes from 'prop-types'
//import MaskedInput from 'react-text-mask'
import useStyles from './styles'
import 'react-multi-date-picker/styles/layouts/mobile.css'
import SignatureInput from './SignatureInput'
import getChannelType from 'fixtures/channelTypes'

export const validateInput = (value, required, validation) => {
  let val = value
  if (val === null || val === undefined)
    val = ""
  if (typeof val === 'string' || val instanceof String) {
    val = val.trim()
    if (required && val.length == 0)
      return false
    if (val.length > 0) {
      if (validation === 'email') {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(val).toLowerCase()))
          return false
      }
    }
  }

  return true
}


function MaskedInput(props) {
  const { inputRef, onChange, value, ...other } = props;

  // Check mask guide here:
  // https://imask.js.org/guide.html

  return (
    <IMaskInput
      mask={Number}
      radix=","
      thousandsSeparator="."
      unmask={true} // true|false|'typed'
      inputRef={el => inputRef(el)}  // access to nested input
      // DO NOT USE onChange TO HANDLE CHANGES!
      // USE onAccept INSTEAD
      onAccept={
        // depending on prop above first argument is
        // `value` if `unmask=false`,
        // `unmaskedValue` if `unmask=true`,
        // `typedValue` if `unmask='typed'`
        (value, mask) => onChange({
          target: {
            name: props.name,
            value,
          }
        })
      }
      value={value === null || value === undefined ? "" : value.toString()}
      {...other}
    />
  )
}

/*
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  function timeMask(value) {
    const chars = value.split('');
    const hours = [
        /[0-2]/,
        chars[0] == '2' ? /[0-3]/ : /[0-9]/
    ];

    const minutes = [ /[0-5]/, /[0-9]/ ];

    return hours.concat(':').concat(minutes);
  }


  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={timeMask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
*/

const NumberInputCustom = (props) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={v => {
        onChange({ target: { name: props.name, value: (v.value) } })
      }}
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={props.type === 'float' ? null : 0}
      allowLeadingZeros
    />
  )
}

export const InputField = (props) => {
  const theme = useTheme()
  const classes = useStyles()

  const {
    type,
    name,
    icon,
    gutterBottom,
    readOnly = false,
    rows = 5,
    rowsMax = 5,
    options,
    renderOption,
    noOptionsText,
    renderGroup,
    filterOptions,
    getOptionDisabled,
    getOptionLabel,
    itemLabelOptionWithKey,
    isLoadingOption,
    groupBy,
    onInputChange,
    value,
    error,
    onChange,
    onReturn,
    placeholder,
    showLabel = true,
    variant,
    size,
    format,
    alignment,
    fullWidth = false,
    clearButton,
    onUploadImage,
    onCancelUpload,
    multipleOptions,
    useMargin,
    inputRef = null,
    required = false,
    helperText = null,
    useMention = false,
    mentionList,
    mentionNode = undefined,
    tintSelected = false,
    allowCustomOption = false,
    onAddNewOption = null,
    isFilter = false,
    onCommand = null,
    usePopupPicker,
    allowReorder = false,
    disabled = false,
    startAdornmentText,
    disableUnderline = false,
    withDescription = false
  } = props
  const { onOpenOptions } = props
  let { hideOptionArrow, hideOptionSelect } = props
  let { onClickOptionInput } = props

  const renderLabel = (label, required) => (
    <span>
      {label}
      {required && (
        <span style={{ color: 'red' }}>&nbsp;*</span>
      )}
    </span>
  )

  const label = props.label ? renderLabel(props?.label, required) : undefined

  const handleOnChange = (value, item) => {
    if (onChange)
      onChange(value, item)
  }

  let startAdornment = null
  let endAdornment = null

  if (icon)
    startAdornment = (
      <InputAdornment position="start">
        {React.createElement(icon, { fontSize: size })}
      </InputAdornment>
    )
  else if (startAdornmentText)
    startAdornment = (
      <InputAdornment position="start">
        {startAdornmentText}
      </InputAdornment>
    )
  else if (format === "currency")
    startAdornment = (
      <InputAdornment position="start">Rp</InputAdornment>
    )

  if ((clearButton === "auto" && value && value?.toString()?.length) || clearButton === "visible") {
    // Show clear button
    // endAdornment = (
    //   <InputAdornment position="end">
    //     <Button kind="clear" size={size} />
    //   </InputAdornment>
    // )
    // endAdornment = (
    //   <InputAdornment position="end">
    //     <IconButton edge="end" onClick={() => handleOnChange(null)}>
    //       <ClearIcon fontSize="small" />
    //     </IconButton>
    //   </InputAdornment>
    // )
    endAdornment = (
      <InputAdornment position="end">
        <div style={{ cursor: "pointer" }} onClick={(e) => {
          e?.stopPropagation()
          handleOnChange(null)
        }}>
          <ClearIcon style={{ fontSize: "1rem", marginTop: ".25rem" }} />
        </div>
      </InputAdornment>
    )


    // End adornment doesn't work with Select dropdown arrow. Hide it
    hideOptionArrow = true
  }

  /*
  const today = new Date();
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [selectedDays, setSelectedDays] = useState(null)
  const trainingSelect = new Date(today.setDate(today.getDate()+14))
  const monthPicker = new Date(trainingSelect.getFullYear(), trainingSelect.getMonth());
  
  const handleCloseCancel = () => {
    setOpenDatePicker(false);
  };

  const handleDayClick = (day, {selected}) => {
    // setSelectedDays(day) 
    setOpenDatePicker(false);
    onChange(moment(day).format('YYYY-MM-DD'))
  }

  const handleSelectDate = (value) => {
    setOpenDatePicker(false);
    let newDate = moment(value.year + '-' + value.month + '-01').format()
    onChange(newDate)
  }
  */

  // Input Fields

  let inputProps = {
    disabled,
    placeholder,
    readOnly,
    startAdornment,
    endAdornment,
    disableUnderline: error ? false : (typeof disableUnderline !== 'undefined' ? disableUnderline : false),
    classes: {
      underline: classes.textFieldInputUnderline,
    },
    onKeyDown: (event) => {
      if (event.keyCode === 13 && !event.shiftKey)
        if (onReturn) {
          event.preventDefault()
          onReturn()
        }
    },
  }

  if (inputRef) inputProps.inputRef = inputRef

  let labelProps = {
    shrink: true,
    classes: {
      shrink: classes.textFieldInputLabel,
    }
  }

  if (value && tintSelected) {
    inputProps["classes"] = {
      ...inputProps["classes"],
      underline: classes.textFieldInputUnderlineSelected,
    }
  }

  if (variant === "clean" || variant === "filled") {
    inputProps["disableUnderline"] = true
    inputProps["classes"] = {
      ...inputProps["classes"],
      underline: classes.textFieldInputClean,
    }
  }

  if (format === "currency" || format === "number") {
    inputProps["inputComponent"] = MaskedInput
    inputProps['scale'] = format === "currency" ? 0 : 2
  }

  inputProps["classes"] = {
    ...inputProps["classes"],
    input: clsx(
      size === "small" && classes.textFieldInputTextSmall,
      size === "large" && classes.textFieldInputTextLarge,
      size === "extra-large" && classes.textFieldInputTextExtraLarge,
      alignment === "right" && classes.textFieldInputTextRightAlignment,
    )
  }

  if (isFilter) {
    inputProps["classes"] = {
      ...inputProps["classes"],
      root: classes.secondaryFieldRoot
    }
  }

  let inputField = null

  if (type === 'checkbox') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={(event) => handleOnChange(event.target.checked)}
            name={name}
            color="primary"
          />
        }
        label={label}
      />
    )
  }
  /*
  else if (type === 'multilevel_option') {

    let selectControl = (
        <Select
          value={value || ""}
          onChange={(event) => handleOnChange(event.target.value)}
          fullWidth
          inputProps={{ readOnly: readOnly }}
          >
            { options.map((o) => o.parent ?
              <MenuItem className={classes.childItem} value={o.uid || o.key }>{ o.text || o.name }</MenuItem>
              :
              <MenuItem value={o.uid || o.key }>{ o.text || o.name }</MenuItem>
            )}
        </Select>    
      )
      
    if (label)
      return (
        <FormControl fullWidth className={classes.formControl} error={error}>
         <InputLabel>{label}</InputLabel>
          { selectControl }
        </FormControl>
      )
    else
      return selectControl
  } 
  */
  else if (type === 'radio') {
    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend" className={classes.legendRadio}>{label}</FormLabel>
        <RadioGroup name={name} value={value || ""}
          onChange={(event) => handleOnChange(event.target.value)}
          row>

          {options.map((o, i) => (
            <Box display='flex' flexDirection={o.description ? 'column' : 'row'} key={i}>
              <FormControlLabel
                control={<Radio color="primary" />}
                value={o.value}
                label={
                  <Box mt={o.description ? 1 : 0}>
                    <Text variant='body1'>
                      {o.text}
                    </Text>
                    {o.description &&
                      <Text variant='body2' className={classes.muted}>{o.description}</Text>
                    }
                  </Box>
                }
              />
            </Box>
          ))}

        </RadioGroup>
      </FormControl>
    )
  }
  /*
  else if (type === 'date') {
    return (
      <>
        <TextField
            label={label}
            value={value ? moment(value).format('DD MMM YYYY') : ""}
            error={error}
            onClick={() => setOpenDatePicker(true)}
            InputProps={{
              readOnly: true,
            }}
            className={classes.blackDisabled}
            fullWidth
          />
          <DialogBox 
            show={openDatePicker}
            onClose={handleCloseCancel}
          >
            <DayPicker
                selectedDays={value}
                onDayClick={handleDayClick}
                disabledDays={{ before: trainingSelect }}
                month={monthPicker}
            />
          </DialogBox>
        </>
    )
  }
  else if (type === 'time') {
    return (
      <FormControl style={{marginTop:'8px'}}>
        <InputLabel htmlFor="formatted-text-mask-input">{label}</InputLabel>
        <Input
          value={value || ""}
          onChange={(event) => handleOnChange(event.target.value)}
          name={name}
          inputComponent={TextMaskCustom}
        />
      </FormControl>
    )
  } 
  else if (type === 'date_year') {
    return (
      <Box>
        <TextField
            label={label}
            value={value ? moment(value).format('MMM YYYY') : ""}
            error={error}
            onClick={() => setOpenDatePicker(true)}
            InputProps={{
              readOnly: true,
            }}
            className={classes.blackDisabled}
            fullWidth
            gutterBottom
          />

          {openDatePicker &&
            <MonthYearPicker 
              onClose={() => setOpenDatePicker(false)}
              onConfirm={handleSelectDate}
              initialData={
                {
                  month: value ? moment(value).format('MM') : '01',
                  year: value ? moment(value).format('YYYY') : moment().format('YYYY')
                }
              }
              />
          }
      </Box>
    )
  } 
  */
  else if (type === 'imagelist') {
    return (
      <ImageListInput
        value={value}
        error={error}
        onUploadImage={onUploadImage}
        onChange={handleOnChange}
      />
    )
  }
  else if (type === 'media' && withDescription) {
    return (
      <FormControl fullWidth={fullWidth} className={clsx(classes.formControl, useMargin && classes.formMargin)} error={error}>
        <InputLabel {...labelProps}>{label}</InputLabel>
        <FileListInput
          onChange={handleOnChange}
          {...{
            name,
            value,
            error,
            onUploadImage,
            onCancelUpload,
            allowReorder
          }}
          multipleValue={multipleOptions}
          readOnly={readOnly}
          style={{ paddingTop: '6px' }}
        />
        {/* <Text variant="caption" color='textMuted'>
          Maximum file size: 16MB
        </Text> */}
      </FormControl>
    )
  }
  else if (type === 'media') {
    if (name.includes('signature')){
      let signatureField = <SignatureInput 
        {...{name, onUploadImage}} 
        onChange={handleOnChange} 
        value={Array.isArray(value) ? value : [value]}
      />
  
      if(label)
        inputField = (
          <FormControl fullWidth={fullWidth} error={error}>
            <FormLabel className={clsx(classes.signatureFieldLabel, useMargin && classes.formMargin)}>{label}</FormLabel>
            {signatureField}
          </FormControl>
        )
      else 
        inputField = signatureField

      return inputField
    } else {
      return (
        <FormControl fullWidth={fullWidth} className={clsx(classes.formControl, useMargin && classes.formMargin)} error={error}>
          <InputLabel {...labelProps}>{label}</InputLabel>
          <FileListInput
            onChange={handleOnChange}
            {...{
              name,
              value,
              error,
              onUploadImage,
              onCancelUpload,
              allowReorder
            }}
            multipleValue={multipleOptions}
            readOnly={readOnly}
            style={{ paddingTop: '6px' }}
          />
          {/* <Text variant="caption" color='textMuted'>
            Maximum file size: 16MB
          </Text> */}
        </FormControl>
      )
    }
  }
  else if (type === 'image]') {
    return (
      <ImageInput
        value={value}
        error={error}
      />
    )
  } 
  else if (type === 'image') {
    return (
      <ImageInput
        value={value}
        error={error}
      />
    )
  }

  // if ((type === 'option' || type === 'select' || Array.isArray(options)) && !multipleOptions) {
  if ((type === 'option' || type === 'select') && !multipleOptions && !allowCustomOption) {
    let selectProps = {}
    if (hideOptionArrow) {
      selectProps["IconComponent"] = () => <></>
      selectProps["classes"] = { select: classes.selectRemoveArrow }
    }

    let selectControl
    selectControl = (
      <Select
        value={value || ""}
        onChange={(event) => {
          event.stopPropagation()
          handleOnChange(event.target.value)
        }}
        fullWidth={fullWidth}
        displayEmpty
        className={clsx(classes.textField, fullWidth && classes.textFieldFullWidth)}
        classes={{ select: clsx(useMargin && classes.selectPadding) }}
        renderValue={value => {
          const opt = options.find(o => o.value === value || isEqual(o.value, value) || o.key === value || o.value === value.value)
          if (opt)
            return opt.text || opt.name
          else
            return placeholder
        }}
        input={<Input {...inputProps} />}
        {...selectProps}
      >
        {options.map((o, i) => o.constructor === Object ?
          <MenuItem value={o.value || o.key} key={i}>{o.text || o.name}</MenuItem>
          :
          <MenuItem value={o} key={i}>{o}</MenuItem>
        )
        }
      </Select>
    )

    if (label)
      inputField = (
        <FormControl fullWidth={fullWidth} className={clsx(classes.formControl, useMargin && classes.formMargin)} error={error}>
          <InputLabel {...labelProps}>{label}</InputLabel>
          {selectControl}
        </FormControl>
      )
    else
      inputField = selectControl
  }
  else if ((type === 'option' || type === 'select') && multipleOptions && isFilter) {
    let selectProps = {}
    if (hideOptionArrow) {
      selectProps["IconComponent"] = () => <></>
      selectProps["classes"] = { select: classes.selectRemoveArrow }
    }

    if (hideOptionSelect) {
      selectProps["MenuProps"] = { style: { display: 'none' } }
    }

    if (onClickOptionInput) {
      inputProps["classes"] = { 
        ...inputProps.classes,
        root: classes.rootOnClickOptionInput
      }

      selectProps["onClick"] = (e) => onClickOptionInput(e)
    }

    let selectControl
    selectControl = (
      <Select
        multiple
        value={value || ""}
        onChange={(event) => {
          event.preventDefault()
          event.stopPropagation()
          if(Array.isArray(event.target.value) && event.target.value.length > 0){
            let newValue = [...event.target.value].map(item => item.toString())
            const frequencyCounter = countBy(newValue)
            const duplicates = pickBy(frequencyCounter, count => count > 1)

            handleOnChange([...event.target.value].filter(item => !Object.keys(duplicates).some(some => some.toString() == item.toString())))
          } else {
            handleOnChange(event.target.value)
          }
        }}
        fullWidth={fullWidth}
        displayEmpty
        className={clsx(classes.textField, fullWidth && classes.textFieldFullWidth)}
        classes={{ select: clsx(useMargin && classes.selectPadding) }}
        renderValue={value => {
          const isRenderWithIconUrl = options.some(some => some.icon_url)

          if(isRenderWithIconUrl){
            const optionsSelected = options.filter(o => value?.includes(o?.value?.toString()))
            const optWithIconUrl = optionsSelected?.map((m, i) => {
              if(m.icon_url) {
                return (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {m.icon_url ? 
                      <img src={getChannelType(m?.code, m?.acc_name).image}
                        style={{ maxWidth: 16, width: 16, marginRight: 6 }}
                    /> : ''} 
                    {m.text || m.name}
                    {(i !== 0 && i !== (optionsSelected.length - 1)) && ', '}
                  </div>
                )
              }
              
              return <>
                {m.text || m.name}
                {i < options.length - 1 && ', '}
              </> 
            })

            if (optWithIconUrl?.length)
              return optWithIconUrl
            else
              return placeholder
          }

          const opt = options.filter(o => value?.includes(o?.value?.toString()))?.map(m => m.text || m.name)
          if (opt?.length)
            return opt.join(', ')
          else
            return placeholder
        }}
        input={<Input {...inputProps} />}
        {...selectProps}
      >
        {options.map((o, i) => o.constructor === Object ?
          <MenuItem value={o.value || o.key} key={i}>
            <Checkbox icon={<span style={{width: '14px'}} />} checkedIcon={<CheckIcon style={{color: 'black', fontSize: '14px'}}/>} checked={value?.includes(o?.value?.toString())} style={{ padding: 0, paddingRight: 4, marginLeft: '-8px'}} />
            {
              o.icon_url ? 
              <img src={getChannelType(o?.code, o?.acc_name).image}
                style={{ maxWidth: 22, width: 22, marginRight: 6 }}
              /> : ''
            }
            {o.text || o.name}
          </MenuItem>
          :
          <MenuItem value={o} key={i}>
            <Checkbox icon={<span style={{width: '14px'}} />} checkedIcon={<CheckIcon style={{color: 'black', fontSize: '14px'}}/>} checked={value?.includes(o?.toString())} style={{ padding: 0, paddingRight: 4, marginLeft: '-8px' }} />
            {o}
          </MenuItem>
        )
        }
      </Select>
    )

    if (label)
      inputField = (
        <FormControl fullWidth={fullWidth} className={clsx(classes.formControl, useMargin && classes.formMargin)} error={error}>
          <InputLabel {...labelProps}>{label}</InputLabel>
          {selectControl}
        </FormControl>
      )
    else
      inputField = selectControl
  }
  else if ((type === 'option' || type === 'select') && multipleOptions && !isFilter) {
    const filter = createFilterOptions()
    inputField = (
      <FormControl className={classes.textField} fullWidth>
        <Autocomplete
          multiple
          autoHighlight
          // freeSolo={Boolean(allowCustomOption)}
          clearOnBlur
          id="combo-box-demo"
          options={options}
          getOptionLabel={(option) => `${itemLabelOptionWithKey && option?.key ? `${option?.key} - ` : ''}${(option.text || option.name)}`}
          loading={isLoadingOption}
          value={value || []}
          // onChange={(_, newValue) => handleOnChange(newValue)}
          onInputChange={onInputChange && ((_, newValue) => onInputChange(newValue))}
          fullWidth
          groupBy={groupBy}
          renderOption={renderOption}
          renderInput={(params) => (
            <TextField
              label={showLabel ? label : null}
              value={value || ""}
              error={error}
              type={type}
              multiline={false}
              margin={size === 'small' ? 'none' : 'normal'}
              fullWidth
              InputProps={inputProps}
              {...params}
              InputLabelProps={labelProps}
              placeholder={placeholder}
              disabled={disabled}
            />
          )}
          _not_used_renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                margin="normal"
                fullWidth
                {...inputProps}
                {...params.inputProps}
              />
            </div>
          )}
          filterOptions={allowCustomOption ? (options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }
            return filtered;
          } : undefined}
          onChange={(_, newValue) => {
            if (allowCustomOption && newValue && newValue[newValue?.length - 1]?.inputValue) {
              onAddNewOption(newValue[newValue?.length - 1]?.inputValue)
            } else {
              handleOnChange(newValue)
            }
          }}
        />
      </FormControl>
    )
  }
  else if ((type === 'option' || type === 'select') && allowCustomOption) {
    const filter = createFilterOptions()
    inputField = (
      <FormControl className={classes.textField} fullWidth>
        <Autocomplete
          id="combo-box-demo"
          autoHighlight
          options={options}
          getOptionLabel={(option) => getOptionLabel ? getOptionLabel(option) : `${itemLabelOptionWithKey && option?.key ? `${option?.key} - ` : ''}${(option.text || option.name)}`}
          value={value}
          onInputChange={onInputChange && ((_, newValue) => onInputChange(newValue))}
          fullWidth
          groupBy={groupBy}
          renderOption={renderOption}
          loading={isLoadingOption}
          renderGroup={renderGroup}
          noOptionsText={noOptionsText}
          renderInput={(params) => {
            const newInputProps = mergeWith(inputProps, params?.InputProps, (ob, sc) => {
              if (isNull(ob)) {
                return sc;
              }
            })

            if(startAdornmentText){
              newInputProps.startAdornment = (
                <div className={classes.customStartAdornmentSelect}>
                  <Text color="textMuted">
                    {startAdornmentText}
                  </Text>
                  <div style={{margin: '0 2px'}} />
                  {params?.InputProps.startAdornment}
                </div>
              )
              if(params?.InputProps.startAdornment){
                newInputProps.placeholder = ''
              }
              newInputProps.endAdornment = null
              newInputProps.margin = 'none'
            }

            return (
              <TextField
                label={showLabel ? label : null}
                value={value || ""}
                error={error}
                type={type}
                multiline={false}
                margin={useMargin ? 'normal' : 'none'}
                fullWidth
                {...params}
                InputProps={newInputProps}
                InputLabelProps={labelProps}
                disabled={disabled}
              />
            )
          }}
          _not_used_renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                margin="normal"
                fullWidth
                {...inputProps}
                {...params.inputProps}
              />
            </div>
          )}
          filterOptions={(options, params) => {
            if(filterOptions){
              return filterOptions(options, params)
            }

            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }
            return filtered;
          }}
          onChange={(_, newValue) => {
            console.log('_ ===> ', _);
            console.log('newValue ===> ', newValue);
            if (newValue && newValue?.inputValue) {
              onAddNewOption(newValue?.inputValue, newValue)
            } else handleOnChange(newValue)
          }}
        />
      </FormControl>
    )
  }
  else if (type === "autocomplete") {
    inputField = (
      <FormControl className={classes.textField} fullWidth>
        <Autocomplete
          id="combo-box-demo"
          onOpen={() => onOpenOptions && onOpenOptions()}
          options={options}
          getOptionDisabled={(option) => getOptionDisabled && getOptionDisabled(option)}
          getOptionLabel={(option) => getOptionLabel ? getOptionLabel(option) : (option.text || option.name)}
          loading={isLoadingOption}
          value={value}
          onChange={(_, newValue) => handleOnChange(newValue)}
          onInputChange={onInputChange && ((_, newValue) => onInputChange(newValue))}
          fullWidth
          // getOptionLabel={getOptionLabel}
          groupBy={groupBy}
          renderOption={renderOption}
          renderInput={(params) => (
            <TextField
              label={showLabel ? label : null}
              value={value || ""}
              error={error}
              type={type}
              multiline={false}
              margin={size === 'small' ? 'none' : 'normal'}
              fullWidth
              InputProps={inputProps}
              {...params}
              InputLabelProps={labelProps}
              disabled={disabled}
              placeholder={placeholder}
            />
          )}
          _not_used_renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <Input
                margin="normal"
                fullWidth
                {...inputProps}
                {...params.inputProps}
              />
            </div>
          )}
        />
      </FormControl>
    )
  }
  else if (type === 'integer' || type === 'float') {
    inputField = (
      <TextField
        label={showLabel ? label : null}
        // value={(type === 'integer' ? parseInt(value) : parseFloat(value)) || ""}
        value={value}
        error={error}
        onChange={(event) => {
          handleOnChange(event.target.value)
        }}
        className={clsx(classes.textField, fullWidth && classes.textFieldFullWidth)}
        margin="normal"
        fullWidth
        name={name}
        type={type}
        InputProps={{ ...inputProps, inputComponent: NumberInputCustom }}
        InputLabelProps={labelProps}
        disabled={disabled}
      />
    )
  }
  else if (type === 'datetime') {
    inputField = (
      <DatePicker
        zIndex={999999}
        // portal={!usePopupPicker}
        className={clsx('yellow', usePopupPicker && 'rmdp-mobile')}
        format='YYYY-MM-DD HH:mm'
        plugins={[<TimePicker hideSeconds position='bottom' />]}
        value={value ? moment(value).format('YYYY-MM-DD HH:mm') : null}
        onChange={v => handleOnChange(v?.toDate()?.toISOString())}
        render={(value, openCalendar) => (
          <TextField
            label={showLabel ? label : null}
            value={value ? moment(value).format('YYYY-MM-DD HH:mm') : null}
            error={error}
            className={clsx(classes.textField, fullWidth && classes.textFieldFullWidth)}
            margin={useMargin ? 'normal' : 'none'}
            fullWidth
            InputProps={{ ...inputProps, readOnly: true }}
            InputLabelProps={labelProps}
            helperText={helperText}
            onClick={openCalendar}
            disabled={disabled}
          />
        )}
      />
    )
  }
  else if (type === 'date') {
    if (multipleOptions) {
      inputField = (
        <DatePicker
          zIndex={999999}
          portal={!usePopupPicker}
          className={clsx('yellow', usePopupPicker && 'rmdp-mobile')}
          format='DD-MMM-YYYY'
          value={[...(value || [])].map(v => moment(v)?.toDate())}
          onChange={val => handleOnChange([...val].map(v => moment(v?.toDate())?.format('YYYY-MM-DD')))}
          plugins={[<DatePanel />]}
          render={(value, openCalendar) => (
            <TextField
              label={showLabel ? label : null}
              value={value}
              error={error}
              className={clsx(classes.textField, fullWidth && classes.textFieldFullWidth)}
              margin={useMargin ? 'normal' : 'none'}
              fullWidth
              InputProps={{ ...inputProps, readOnly: true }}
              InputLabelProps={labelProps}
              helperText={helperText}
              onClick={openCalendar}
              disabled={disabled}
            />
          )}
        />
      )
    } else inputField = (
      <DatePicker
        zIndex={999999}
        portal={!usePopupPicker}
        className={clsx('yellow', usePopupPicker && 'rmdp-mobile')}
        format='DD/MM/YYYY'
        value={moment(value, 'YYYY-MM-DD')?.toDate()}
        onChange={v => handleOnChange(moment(v?.toDate())?.format('YYYY-MM-DD'))}
        render={(value, openCalendar) => (
          <TextField
            label={showLabel ? label : null}
            value={value}
            error={error}
            className={clsx(classes.textField, fullWidth && classes.textFieldFullWidth)}
            margin={useMargin ? 'normal' : 'none'}
            fullWidth
            InputProps={{ ...inputProps, readOnly: true }}
            InputLabelProps={labelProps}
            helperText={helperText}
            onClick={openCalendar}
            disabled={disabled}
          />
        )}
      />
    )
  }
  else if (type === 'time') {
    inputField = (
      <DatePicker
        zIndex={999999}
        portal
        disableDayPicker
        format="HH:mm"
        className={clsx('yellow')}
        plugins={[<TimePicker hideSeconds />]}
        value={value}
        onChange={v => {handleOnChange(v)}}
        render={(value, openCalendar) => (
          <TextField
            label={showLabel ? label : null}
            value={value}
            error={error}
            className={clsx(classes.textField, fullWidth && classes.textFieldFullWidth)}
            margin={useMargin ? 'normal' : 'none'}
            fullWidth
            InputProps={{ ...inputProps, readOnly: true }}
            InputLabelProps={labelProps}
            helperText={helperText}
            onClick={openCalendar}
            disabled={disabled}
          />
        )}
      />
    )
  }
  else if (type === 'date-picker') {
    inputField = (
      <MuiDatePicker
        disableToolbar
        variant="inline"
        format="DD MMM YYYY"
        margin={useMargin ? 'normal' : 'none'}
        value={value ? moment(value, 'YYYY-MM-DD') : null}
        onChange={e => handleOnChange(moment(e).format('YYYY-MM-DD'))}
        emptyLabel={placeholder}
        fullWidth
        name={name}
        autoOk
        TextFieldComponent={props => <TextField {...props} InputProps={inputProps} fullWidth={fullWidth} error={error} helperText={helperText} disabled={disabled} />}
      />
    )
  }
  else if (type === 'time-picker') {
    inputField = (
      <MuiTimePicker
        // disableToolbar
        variant="dialog"
        format="HH:mm"
        margin={useMargin ? 'normal' : 'none'}
        value={value ? moment(value, 'HH:mm') : value}
        onChange={e => handleOnChange(moment(e).format('HH:mm'))}
        emptyLabel={placeholder}
        fullWidth
        name={name}
        ampm={false}
        autoOk
        TextFieldComponent={props => <TextField {...props} InputProps={inputProps} fullWidth={fullWidth} error={error} helperText={helperText} disabled={disabled} />}
        views={["hours", "minutes"]}
      />
    )
  }
  else if (showLabel) {
    inputField = (
      <TextField
        label={showLabel ? label : null}
        value={value || ""}
        error={error}
        type={type}
        multiline={(type === 'textarea' || type === 'richtext') ? true : false}
        rows={rows}
        rowsMax={rowsMax}
        onChange={(event) => handleOnChange(event.target.value)}
        className={clsx(classes.textField, fullWidth && classes.textFieldFullWidth)}
        margin="normal"
        fullWidth
        InputProps={inputProps}
        InputLabelProps={labelProps}
        helperText={helperText}
        disabled={disabled}
      />
    )
  }
  else if (useMention) {
    inputField = (
      <FormControl
        className={clsx(classes.textField, fullWidth && classes.textFieldFullWidth)}
        fullWidth={fullWidth}
      >
        <MentionsInput
          disabled={disabled}
          value={value || ""}
          onChange={(event) => handleOnChange(event.target.value)}
          allowSuggestionsAboveCursor={true}
          forceSuggestionsAboveCursor={true}
          placeholder={placeholder}
          // className={classes.mentionInputField}
          className="goapp-mention-input"
          suggestionsPortalHost={mentionNode}
          inputRef={inputRef}
          onKeyDown={(event) => {
            if (event.keyCode === 13 && !event.shiftKey)
              if (onReturn) {
                event.preventDefault()
                onReturn()
              }

            if (event.keyCode === 191 && !event.shiftKey && value?.length === 0)
              if (onCommand) {
                event.preventDefault()
                onCommand()
              }
          }}
        >
          <Mention
            trigger="@"
            data={mentionList}
            markup="@__display__@"
            style={{
              backgroundColor: "#ff6f00",
              opacity: 0.3,
            }}
          />
        </MentionsInput>
      </FormControl>
    )
  }
  else {
    inputField = (
      <FormControl className={clsx(classes.textField, fullWidth && classes.textFieldFullWidth)}
        fullWidth={fullWidth}
      >
        <Input
          value={value || ""}
          error={error}
          type={type}
          multiline={(type === 'textarea' || type === 'richtext') ? true : false}
          variant={variant}
          rows={rows}
          rowsMax={rowsMax}
          onChange={(event) => handleOnChange(event.target.value)}
          margin="normal"
          fullWidth
          {...inputProps}
        />
      </FormControl>
    )
  }

  if (variant == "filled")
    return (
      <div className={classes.inputFieldFilled}>
        {inputField}
      </div>
    )
  else
    return inputField
}

export default InputField